import React, { useState } from 'react';
import { Mail, CheckCircle, ArrowLeft, Send } from 'lucide-react';

const EmailVerificationModal = ({ email, onClose, onResendEmail }) => {
  const [resendStatus, setResendStatus] = useState({
    loading: false,
    message: '',
    isError: false
  });

  const handleResend = async () => {
    setResendStatus({ loading: true, message: '', isError: false });
    
    try {
      await onResendEmail();
      setResendStatus({
        loading: false,
        message: 'Email reenviado com sucesso!',
        isError: false
      });

      // Limpar mensagem após 3 segundos
      setTimeout(() => {
        setResendStatus(prev => ({ ...prev, message: '' }));
      }, 3000);
    } catch (error) {
      setResendStatus({
        loading: false,
        message: error.message || 'Erro ao reenviar email',
        isError: true
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900/80 flex items-center justify-center p-4 z-50">
      <div className="w-full max-w-md bg-gray-800 rounded-lg shadow-xl p-8">
        <div className="flex flex-col items-center text-center space-y-6">
          {/* Ícone de Email */}
          <div className="p-3 bg-blue-600 rounded-full">
            <Mail className="w-8 h-8 text-white" />
          </div>
          
          {/* Título e Subtítulo */}
          <div className="space-y-2">
            <h2 className="text-2xl font-bold text-white">Verifique seu email</h2>
            <p className="text-gray-300">
              Enviamos um link de confirmação para:
            </p>
            <p className="font-medium text-blue-400">
              {email}
            </p>
          </div>
          
          {/* Instruções */}
          <div className="bg-gray-700/50 p-4 rounded-lg space-y-3 w-full">
            <div className="flex items-start space-x-3">
              <CheckCircle className="w-5 h-5 text-green-500 shrink-0 mt-0.5" />
              <p className="text-sm text-gray-300 text-left">
                Clique no link enviado para seu email para ativar sua conta
              </p>
            </div>
          </div>
          
          {/* Status do Reenvio */}
          {resendStatus.message && (
            <div className={`text-sm ${
              resendStatus.isError ? 'text-red-400' : 'text-green-400'
            }`}>
              {resendStatus.message}
            </div>
          )}
          
          {/* Botões */}
          <div className="w-full space-y-3">
            <button
              onClick={onClose}
              className="w-full py-2.5 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center space-x-2"
            >
              <ArrowLeft className="w-4 h-4" />
              <span>Voltar para o Login</span>
            </button>

            <button
              onClick={handleResend}
              disabled={resendStatus.loading}
              className={`w-full py-2.5 px-4 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center justify-center space-x-2 ${
                resendStatus.loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <Send className="w-4 h-4" />
              <span>
                {resendStatus.loading ? 'Reenviando...' : 'Reenviar Email'}
              </span>
            </button>
          </div>
          
          {/* Dica */}
          <p className="text-sm text-gray-400">
            Não recebeu o email? Verifique sua caixa de spam ou clique em reenviar
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationModal;