import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/Card';
import { useAuth } from '../../context/AuthContext';
import { database } from '../../services/firebase';
import { Clock, Users, Key, RefreshCw } from 'lucide-react';
import InviteCodesList from './InviteCodesList';

const AdminPanel = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    expiresDays: 30,
    subscriptionDays: 30,
    maxUses: 1,
    description: '',
    isTestMode: false,
    subscriptionSeconds: 60,
  });
  const [loading, setLoading] = useState(false);

  const handleGenerateCode = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const code = Math.random().toString(36).substring(2, 8).toUpperCase();
      const now = Date.now();
      
      // Calcula a duração da assinatura baseado no modo
      const subscriptionDuration = formData.isTestMode
        ? formData.subscriptionSeconds * 1000 // Converte segundos para milissegundos
        : formData.subscriptionDays * 24 * 60 * 60 * 1000; // Converte dias para milissegundos
      
      await database.ref('inviteCodes').child(code).set({
        created: now,
        createdBy: user.email,
        expiresAt: now + (formData.expiresDays * 24 * 60 * 60 * 1000),
        maxUses: parseInt(formData.maxUses),
        usedCount: 0,
        used: false,
        description: formData.description,
        subscriptionDays: formData.isTestMode ? 0 : parseInt(formData.subscriptionDays),
        isTestMode: formData.isTestMode,
        subscriptionSeconds: formData.isTestMode ? parseInt(formData.subscriptionSeconds) : 0,
        subscriptionDuration // Armazena a duração em milissegundos
      });

      setFormData(prev => ({ ...prev, description: '' }));
    } catch (error) {
      console.error('Erro ao gerar código:', error);
      alert('Erro ao gerar código de convite');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center space-x-2">
            <Key className="h-6 w-6" />
            <span>Gerador de Códigos de Convite</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleGenerateCode} className="space-y-6">
            {/* Modo de Teste Switch */}
            <div className="flex items-center space-x-2">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.isTestMode}
                  onChange={e => setFormData(prev => ({ ...prev, isTestMode: e.target.checked }))}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
              <span className="text-sm text-gray-300">Modo de Teste (duração em segundos)</span>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Duração do código */}
              <div className="space-y-2">
                <label className="flex items-center space-x-2 text-sm text-gray-300">
                  <Clock className="h-4 w-4" />
                  <span>Duração do código (dias)</span>
                </label>
                <input
                  type="number"
                  value={formData.expiresDays}
                  onChange={e => setFormData(prev => ({ ...prev, expiresDays: e.target.value }))}
                  className="w-full px-3 py-2 bg-gray-700 rounded text-white"
                  min="1"
                />
              </div>

              {/* Duração da assinatura */}
              <div className="space-y-2">
                <label className="flex items-center space-x-2 text-sm text-gray-300">
                  <RefreshCw className="h-4 w-4" />
                  <span>
                    Duração da assinatura ({formData.isTestMode ? 'segundos' : 'dias'})
                  </span>
                </label>
                <input
                  type="number"
                  value={formData.isTestMode ? formData.subscriptionSeconds : formData.subscriptionDays}
                  onChange={e => setFormData(prev => ({
                    ...prev,
                    [formData.isTestMode ? 'subscriptionSeconds' : 'subscriptionDays']: e.target.value
                  }))}
                  className="w-full px-3 py-2 bg-gray-700 rounded text-white"
                  min="1"
                />
              </div>

              {/* Usos máximos */}
              <div className="space-y-2">
                <label className="flex items-center space-x-2 text-sm text-gray-300">
                  <Users className="h-4 w-4" />
                  <span>Usos máximos</span>
                </label>
                <input
                  type="number"
                  value={formData.maxUses}
                  onChange={e => setFormData(prev => ({ ...prev, maxUses: e.target.value }))}
                  className="w-full px-3 py-2 bg-gray-700 rounded text-white"
                  min="1"
                />
              </div>

              {/* Descrição */}
              <div className="space-y-2">
                <label className="block text-sm text-gray-300">
                  Descrição (opcional)
                </label>
                <input
                  type="text"
                  value={formData.description}
                  onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
                  className="w-full px-3 py-2 bg-gray-700 rounded text-white"
                  placeholder="Ex: Código para Beta Testers"
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? 'Gerando...' : 'Gerar Código'}
            </button>
          </form>
        </CardContent>
      </Card>

      <InviteCodesList />
    </div>
  );
};

export default AdminPanel;