import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Card } from '../ui/Card';
import { AlertCircle, ArrowDown, ArrowUp, Car } from 'lucide-react';
import { GT3_CARS } from '../../utils/constants';

const DownforceCalculator = () => {
 const [selectedCar, setSelectedCar] = useState(null);
 const [isOpen, setIsOpen] = useState(false);
 const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
 const [currentSettings, setCurrentSettings] = useState({
   wingAngle: null,
   frontHeight: 0,
   rearHeight: 0,
 });

 const [targetSettings, setTargetSettings] = useState({
   wingAngle: null,
 });

 const [changes, setChanges] = useState(null);

 useEffect(() => {
   if (isOpen) {
     const button = document.getElementById('wing-angle-selector');
     if (button) {
       const rect = button.getBoundingClientRect();
       setDropdownPosition({
         top: rect.bottom + window.scrollY + 4,
         left: rect.left + window.scrollX,
         width: rect.width
       });
     }
   }
 }, [isOpen]);

 useEffect(() => {
   if (selectedCar && currentSettings.wingAngle !== null && targetSettings.wingAngle !== null) {
     calculateChanges();
   }
 }, [selectedCar, targetSettings.wingAngle]);

 const calculateChanges = () => {
   if (!selectedCar) return;

   const carData = GT3_CARS[selectedCar];
   const wingDiff = targetSettings.wingAngle - currentSettings.wingAngle;
   
   const suggestedChanges = {
     wing: wingDiff,
     option1: {
       front: -(wingDiff * carData.adjustments.frontPerDegree),
       rear: 0,
       description: 'Ajuste na altura dianteira'
     },
     option2: {
       front: 0,
       rear: wingDiff * carData.adjustments.rearPerDegree,
       description: 'Ajuste na altura traseira'
     },
     option3: {
       front: -(wingDiff * carData.adjustments.frontPerDegree / 2),
       rear: wingDiff * carData.adjustments.rearPerDegree / 2,
       description: 'Ajuste combinado (recomendado)'
     }
   };

   setChanges(suggestedChanges);
 };

 const handleCarSelect = (carKey) => {
   setSelectedCar(carKey);
   setCurrentSettings({
     wingAngle: null,
     frontHeight: 0,
     rearHeight: 0,
   });
   setTargetSettings({
     wingAngle: null,
   });
   setChanges(null);
 };

 return (
   <div className="space-y-6">
     <Card className="bg-gray-800/50 backdrop-blur">
       <div className="p-6">
         <h2 className="text-xl font-bold text-white mb-6">Calculadora de Ajustes Aerodinâmicos</h2>
         
         {/* Seleção do Carro */}
         <div className="mb-8">
           <label className="block text-sm font-medium text-gray-300 mb-2">
             Selecione seu carro
           </label>
           <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
             {Object.entries(GT3_CARS).map(([key, car]) => (
               <button
                 key={key}
                 onClick={() => handleCarSelect(key)}
                 className={`p-4 rounded-lg flex items-center space-x-2 transition-colors ${
                   selectedCar === key
                     ? 'bg-blue-600 text-white'
                     : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                 }`}
               >
                 <Car className="h-5 w-5" />
                 <span>{car.name}</span>
               </button>
             ))}
           </div>
         </div>

         {selectedCar && (
           <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
             {/* Configurações Atuais e Alvo */}
             <div className="space-y-6">
               {/* Ângulo Atual */}
               <div className="relative">
                 <label className="block text-sm font-medium text-gray-300 mb-2">
                   Ângulo Atual da Asa
                 </label>
                 <div 
                   id="wing-angle-selector"
                   className="w-full px-3 py-2 bg-gray-700 rounded text-white cursor-pointer"
                   onClick={() => setIsOpen(!isOpen)}
                 >
                   {currentSettings.wingAngle ? `${currentSettings.wingAngle.toFixed(1)}°` : 'Selecione o ângulo'}
                 </div>
                 
                 {isOpen && createPortal(
                   <>
                     <div 
                       className="fixed inset-0 bg-transparent"
                       onClick={() => setIsOpen(false)}
                     />
                     <div 
                       style={{
                         position: 'absolute',
                         zIndex: 9999,
                         left: `${dropdownPosition.left}px`,
                         top: `${dropdownPosition.top}px`,
                         width: `${dropdownPosition.width}px`
                       }}
                       className="bg-gray-800 border border-gray-700 rounded-lg shadow-xl max-h-64 overflow-y-auto"
                     >
                       {Array.from(
                         { length: (GT3_CARS[selectedCar].wingRange.max - GT3_CARS[selectedCar].wingRange.min) * 2 + 1 },
                         (_, i) => {
                           const angle = GT3_CARS[selectedCar].wingRange.min + (i * 0.5);
                           const range = GT3_CARS[selectedCar].wingRange.max - GT3_CARS[selectedCar].wingRange.min;
                           const lowThreshold = GT3_CARS[selectedCar].wingRange.min + (range * 0.3);
                           const mediumThreshold = GT3_CARS[selectedCar].wingRange.min + (range * 0.7);
                           
                           const level = angle <= lowThreshold ? 'low' : 
                                     angle <= mediumThreshold ? 'medium' : 
                                     'high';
                           
                           let headerShown = false;
                           if (i === 0) headerShown = true;
                           else {
                             const prevAngle = GT3_CARS[selectedCar].wingRange.min + ((i - 1) * 0.5);
                             const prevLevel = prevAngle <= lowThreshold ? 'low' : 
                                           prevAngle <= mediumThreshold ? 'medium' : 
                                           'high';
                             if (level !== prevLevel) headerShown = true;
                           }

                           return (
                             <div key={angle}>
                               {headerShown && (
                                 <div className={`px-3 py-2 text-sm font-bold ${
                                   level === 'low' ? 'bg-red-700/40 text-red-300' :
                                   level === 'medium' ? 'bg-amber-700/40 text-amber-300' :
                                   'bg-emerald-700/40 text-emerald-300'
                                 }`}>
                                   {level === 'low' ? 'Asas Baixas' :
                                    level === 'medium' ? 'Asas Médias' :
                                    'Asas Altas'}
                                 </div>
                               )}
                               <div
                                 className={`px-3 py-1.5 hover:bg-gray-700/50 cursor-pointer ${
                                   level === 'low' ? 'text-red-300' :
                                   level === 'medium' ? 'text-amber-300' :
                                   'text-emerald-300'
                                 }`}
                                 onClick={() => {
                                   setCurrentSettings(prev => ({
                                     ...prev,
                                     wingAngle: angle
                                   }));
                                   setIsOpen(false);
                                 }}
                               >
                                 {angle.toFixed(1)}°
                               </div>
                             </div>
                           );
                         }
                       )}
                     </div>
                   </>,
                   document.body
                 )}
               </div>

               {/* Ângulo Desejado */}
               {currentSettings.wingAngle !== null && (
                 <div>
                   <label className="block text-sm font-medium text-gray-300 mb-2">
                     Ângulo Desejado: {targetSettings.wingAngle?.toFixed(1)}°
                   </label>
                   <input
                     type="range"
                     min={GT3_CARS[selectedCar].wingRange.min}
                     max={GT3_CARS[selectedCar].wingRange.max}
                     step="0.5"
                     value={targetSettings.wingAngle ?? currentSettings.wingAngle}
                     onChange={(e) => setTargetSettings(prev => ({
                       ...prev,
                       wingAngle: Number(e.target.value)
                     }))}
                     className="w-full"
                   />
                   <div className="flex justify-between text-sm text-gray-400">
                     <span>{GT3_CARS[selectedCar].wingRange.min}°</span>
                     <span>{(targetSettings.wingAngle ?? currentSettings.wingAngle).toFixed(1)}°</span>
                     <span>{GT3_CARS[selectedCar].wingRange.max}°</span>
                   </div>
                 </div>
               )}
             </div>

             {/* Ajustes Recomendados */}
             {changes && changes.wing !== 0 && (
               <div className="space-y-4">
                 <h3 className="text-lg font-medium text-white">Ajustes Necessários</h3>
                 
                 <div className="space-y-3">
                   {/* Opção 1 */}
                   <div className="bg-gray-700/50 rounded-lg p-4">
                     <h4 className="text-white font-medium mb-2">Opção 1: Altura Dianteira</h4>
                     <div className="flex items-center space-x-2 text-gray-300">
                       <ArrowUp className="h-4 w-4" />
                       <span>Front: {changes.option1.front.toFixed(1)}mm</span>
                     </div>
                   </div>

                   {/* Opção 2 */}
                   <div className="bg-gray-700/50 rounded-lg p-4">
                     <h4 className="text-white font-medium mb-2">Opção 2: Altura Traseira</h4>
                     <div className="flex items-center space-x-2 text-gray-300">
                       <ArrowDown className="h-4 w-4" />
                       <span>Rear: {changes.option2.rear.toFixed(1)}mm</span>
                     </div>
                   </div>

                   {/* Opção 3 - Recomendada */}
                   <div className="bg-green-900/30 rounded-lg p-4">
                     <div className="flex items-center space-x-2 mb-2">
                       <h4 className="text-white font-medium">Opção 3: Ajuste Combinado</h4>
                       <span className="px-2 py-1 text-xs bg-green-500/20 text-green-300 rounded">
                         Recomendado
                       </span>
                     </div>
                     <div className="space-y-2 text-gray-300">
                       <div className="flex items-center space-x-2">
                         <ArrowUp className="h-4 w-4" />
                         <span>Front: {changes.option3.front.toFixed(1)}mm</span>
                       </div>
                       <div className="flex items-center space-x-2">
                         <ArrowDown className="h-4 w-4" />
                         <span>Rear: {changes.option3.rear.toFixed(1)}mm</span>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             )}
           </div>
         )}
       </div>
     </Card>
   </div>
 );
};

export default DownforceCalculator;