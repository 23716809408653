import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { authService } from '../../services/authService';

const SubscriptionRenewalModal = () => {
  const [inviteCode, setInviteCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [timeLeft, setTimeLeft] = useState(5 * 60);
  const { renewSubscription, logout, user } = useAuth();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          logout();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [logout]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await renewSubscription(inviteCode.trim().toUpperCase());
      await authService.handleSuccessfulRenewal(user.uid);
      window.location.reload();
    } catch (error) {
      setError(error.message || 'Erro ao renovar assinatura');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen flex items-center justify-center p-4">
      <div className="w-full max-w-sm bg-gray-800/95 rounded-lg shadow-xl p-6 backdrop-blur-sm">
        {/* Logo */}
        <div className="text-center mb-6">
          <img 
            src="/assets/logo_isetup1.png" 
            alt="iSetup" 
            className="mx-auto h-36"
          />
        </div>

        <h2 className="text-2xl font-bold mb-6 text-center text-white">
          Renovação de Assinatura
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email atual (não editável) */}
          <div>
            <label className="block text-sm font-medium mb-1 text-gray-300">
              Email
            </label>
            <input
              type="text"
              value={user?.email || ''}
              disabled
              className="w-full px-3 py-2 bg-gray-700/50 rounded border border-gray-600 text-gray-400"
            />
          </div>

          {/* Campo do código de convite */}
          <div>
            <label className="block text-sm font-medium mb-1 text-gray-300">
              Código de Convite
            </label>
            <input
              type="text"
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value.toUpperCase())}
              className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              placeholder="Digite o código aqui"
              required
            />
          </div>

          {/* Mensagem de erro */}
          {error && (
            <div className="text-red-500 text-sm text-center bg-red-500/10 rounded-md py-2">
              {error}
            </div>
          )}

          {/* Mensagem de aviso com contador */}
          <div className="text-yellow-400 text-sm text-center bg-yellow-500/10 rounded-md py-2">
            Sua assinatura expirou. Você tem {formatTime(timeLeft)} para renovar antes de ser deslogado.
          </div>

          {/* Botões */}
          <div className="space-y-3 pt-2">
            <button
              type="submit"
              disabled={loading}
              className={`w-full bg-blue-600 hover:bg-blue-700 text-white rounded py-2 font-medium transition-colors
                ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {loading ? 'Renovando...' : 'Renovar Assinatura'}
            </button>

            <button
              type="button"
              onClick={logout}
              className="w-full bg-transparent border border-red-500 text-red-500 hover:bg-red-500/10 rounded py-2 font-medium transition-colors"
            >
              Sair da Conta
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionRenewalModal;