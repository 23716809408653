import React from 'react';

export const Card = ({ children, className = '', ...props }) => (
    <div className={`bg-gray-800 rounded-lg shadow-xl ${className}`} {...props}>
      {children}
    </div>
  );

export const CardHeader = ({ children, className = '', ...props }) => (
    <div className={`p-6 ${className}`} {...props}>
      {children}
    </div>
  );

  export const CardTitle = ({ children, className = '', ...props }) => (
    <h3 className={`text-xl font-semibold text-white ${className}`} {...props}>
      {children}
    </h3>
  );

  export const CardContent = ({ children, className = '', ...props }) => (
    <div className={`px-6 pb-6 ${className}`} {...props}>
      {children}
    </div>
  );

export const CardFooter = ({ children, className = '', ...props }) => (
  <div
    className={`px-6 py-4 border-t border-gray-700 ${className}`}
    {...props}
  >
    {children}
  </div>
);