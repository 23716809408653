// src/utils/carSuspensionData.js

export const CAR_SUSPENSION_DATA = {
    "BMW M4 GT3": {
      arb: {
        front: {
          options: 11, // D1-D1 (softest) até D6-D6 (stiffest)
          description: "11 configurações de ARB disponíveis, D1-D1 (mais macio) até D6-D6 (mais duro)"
        },
        rear: {
          options: 7, // D1-D1 (softest) até D4-D4 (stiffest)
          description: "7 configurações de ARB disponíveis, D1-D1 (mais macio) até D4-D4 (mais duro)"
        }
      },
      springs: {
        recommendedRanges: {
          front: {
            min: 130000,
            max: 250000,
            unit: "N/m",
            step: 10000
          },
          rear: {
            min: 120000,
            max: 240000,
            unit: "N/m", 
            step: 10000
          }
        }
      }
    },
    "McLaren 720S GT3": {
      arb: {
        front: {
          options: 8, // #1 (softest) até #8 (stiffest)
          description: "#1 (mais macio) até #8 (mais duro)"
        },
        rear: {
          options: 7, // #1 (softest) até #7 (stiffest) 
          description: "#1 (mais macio) até #7 (mais duro)"
        }
      },
      springs: {
        recommendedRanges: {
          front: {
            min: 120000,
            max: 260000,
            unit: "N/m",
            step: 10000
          },
          rear: {
            min: 110000,
            max: 250000,
            unit: "N/m",
            step: 10000
          }
        }
      }
    },
    "Audi R8 GT3": {
      arb: {
        front: {
          options: 6, // 1-1 (softest) até 3-3 (stiffest)
          description: "6 configurações de ARB disponíveis, 1-1 (mais macio) até 3-3 (mais duro)"
        },
        rear: {
          options: 6, // 1-1 (softest) até 3-3 (stiffest)
          description: "6 configurações de ARB disponíveis, 1-1 (mais macio) até 3-3 (mais duro)"
        }
      },
      springs: {
        recommendedRanges: {
          front: {
            min: 140000,
            max: 260000,
            unit: "N/m",
            step: 10000
          },
          rear: {
            min: 130000,
            max: 250000,
            unit: "N/m",
            step: 10000
          }
        }
      }
    },
    "Ferrari 296 GT3": {
      arb: {
        front: {
          options: 6, // 1-1 (softest) até 3-3 (stiffest)
          description: "6 configurações de ARB disponíveis, 1-1 (mais macio) até 3-3 (mais duro)"
        },
        rear: {
          options: 6, // 1-1 (softest) até 3-3 (stiffest)
          description: "6 configurações de ARB disponíveis, 1-1 (mais macio) até 3-3 (mais duro)"
        }
      },
      springs: {
        recommendedRanges: {
          front: {
            min: 135000,
            max: 255000,
            unit: "N/m",
            step: 10000
          },
          rear: {
            min: 125000,
            max: 245000,
            unit: "N/m",
            step: 10000
          }
        }
      }
    },
    "Lamborghini Huracan GT3 EVO": {
      arb: {
        front: {
          options: 6, // 1-1 (softest) até 3-3 (stiffest)
          description: "6 configurações de ARB disponíveis, 1-1 (mais macio) até 3-3 (mais duro)"
        },
        rear: {
          options: 6, // 1-1 (softest) até 3-3 (stiffest)
          description: "6 configurações de ARB disponíveis, 1-1 (mais macio) até 3-3 (mais duro)"
        }
      },
      springs: {
        recommendedRanges: {
          front: {
            min: 135000,
            max: 255000,
            unit: "N/m",
            step: 10000
          },
          rear: {
            min: 125000,
            max: 245000,
            unit: "N/m",
            step: 10000
          }
        }
      }
    }
  };
  
  // Função para obter recomendação baseada no carro
  export const getCarSuspensionRecommendation = (carModel, issue) => {
    const carData = CAR_SUSPENSION_DATA[carModel];
    if (!carData) return null;
  
    const recommendations = {
      oversteer: {
        springs: `Considere aumentar a rigidez das molas dianteiras para ${carData.springs.recommendedRanges.front.min + 30000}${carData.springs.recommendedRanges.front.unit}`,
        arb: "Aumente a rigidez da barra estabilizadora dianteira em 1-2 posições"
      },
      understeer: {
        springs: `Considere aumentar a rigidez das molas traseiras para ${carData.springs.recommendedRanges.rear.min + 30000}${carData.springs.recommendedRanges.rear.unit}`,
        arb: "Aumente a rigidez da barra estabilizadora traseira em 1-2 posições"
      },
      general: {
        springRanges: {
          front: `Molas dianteiras: ${carData.springs.recommendedRanges.front.min} - ${carData.springs.recommendedRanges.front.max} ${carData.springs.recommendedRanges.front.unit}`,
          rear: `Molas traseiras: ${carData.springs.recommendedRanges.rear.min} - ${carData.springs.recommendedRanges.rear.max} ${carData.springs.recommendedRanges.rear.unit}`
        },
        arbOptions: {
          front: carData.arb.front.description,
          rear: carData.arb.rear.description
        }
      }
    };
  
    return recommendations;
  };