import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/Card';
import { database } from '../../services/firebase';
import { formatDate } from '../../utils/helpers';
import { AlertCircle, CheckCircle2, Copy, Users, Trash2 } from 'lucide-react';

const InviteCodesList = () => {
 const [codes, setCodes] = useState([]);
 const [loading, setLoading] = useState(true);
 const [filter, setFilter] = useState('all');
 const [page, setPage] = useState(1);
 const itemsPerPage = 10;

 useEffect(() => {
   const codesRef = database.ref('inviteCodes');
   
   const handleData = (snapshot) => {
     const data = snapshot.val();
     if (data) {
       const codesList = Object.entries(data).map(([code, details]) => ({
         code,
         ...details,
       })).sort((a, b) => b.created - a.created);
       setCodes(codesList);
     } else {
       setCodes([]);
     }
     setLoading(false);
   };

   codesRef.on('value', handleData);
   return () => codesRef.off('value', handleData);
 }, []);

 const getFilteredCodes = () => {
   switch (filter) {
     case 'used':
       return codes.filter(code => code.usedCount >= code.maxUses);
     case 'unused':
       return codes.filter(code => code.usedCount < code.maxUses);
     default:
       return codes;
   }
 };

 const paginatedCodes = () => {
   const filteredCodes = getFilteredCodes();
   const start = (page - 1) * itemsPerPage;
   const end = start + itemsPerPage;
   return filteredCodes.slice(start, end);
 };

 const totalPages = Math.ceil(getFilteredCodes().length / itemsPerPage);

 const copyToClipboard = (code) => {
   navigator.clipboard.writeText(code);
 };

 const deleteCode = async (code) => {
   if (window.confirm('Tem certeza que deseja excluir este código?')) {
     try {
       await database.ref(`inviteCodes/${code}`).remove();
     } catch (error) {
       console.error('Erro ao excluir código:', error);
       alert('Erro ao excluir o código. Tente novamente.');
     }
   }
 };

 const getStatusColor = (code) => {
   const now = Date.now();
   if (now > code.expiresAt) return 'text-gray-400';
   if (code.usedCount >= code.maxUses) return 'text-red-400';
   return 'text-green-400';
 };

 if (loading) {
   return (
     <Card className="mt-8">
       <CardContent className="p-6">
         <div className="flex items-center justify-center">
           <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
         </div>
       </CardContent>
     </Card>
   );
 }

 return (
   <Card className="mt-8">
     <CardHeader>
       <div className="flex justify-between items-center">
         <CardTitle className="text-xl">Códigos de Convite</CardTitle>
         <div className="flex gap-2">
           <select
             value={filter}
             onChange={(e) => {
               setFilter(e.target.value);
               setPage(1);
             }}
             className="bg-gray-700 text-white rounded px-3 py-1 text-sm"
           >
             <option value="all">Todos</option>
             <option value="unused">Disponíveis</option>
             <option value="used">Utilizados</option>
           </select>
         </div>
       </div>
     </CardHeader>
     <CardContent>
       <div className="space-y-4">
         {paginatedCodes().length === 0 ? (
           <div className="text-center text-gray-400 py-6">
             Nenhum código encontrado
           </div>
         ) : (
           <>
             {paginatedCodes().map((code) => (
               <div
                 key={code.code}
                 className="bg-gray-800 rounded-lg p-4 flex flex-col gap-3"
               >
                 <div className="flex justify-between items-start">
                   <div className="flex items-center gap-2">
                     <span className={`font-mono text-lg ${getStatusColor(code)}`}>
                       {code.code}
                     </span>
                     <button
                       onClick={() => copyToClipboard(code.code)}
                       className="p-1 hover:bg-gray-700 rounded"
                       title="Copiar código"
                     >
                       <Copy className="h-4 w-4 text-gray-400" />
                     </button>
                   </div>
                   <div className="flex items-center gap-4">
                     <div className="flex items-center gap-1 text-sm text-gray-400">
                       <Users className="h-4 w-4" />
                       <span>{code.usedCount}/{code.maxUses}</span>
                     </div>
                     {Date.now() > code.expiresAt ? (
                       <AlertCircle className="h-5 w-5 text-gray-400" title="Expirado" />
                     ) : code.usedCount >= code.maxUses ? (
                       <AlertCircle className="h-5 w-5 text-red-400" title="Utilizado" />
                     ) : (
                       <CheckCircle2 className="h-5 w-5 text-green-400" title="Disponível" />
                     )}
                     <button
                       onClick={() => deleteCode(code.code)}
                       className="p-1 hover:bg-gray-700 rounded text-red-400 hover:text-red-300"
                       title="Excluir código"
                     >
                       <Trash2 className="h-4 w-4" />
                     </button>
                   </div>
                 </div>
                 <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-sm text-gray-400">
                   <div>
                     <span className="block">Criado em</span>
                     <span className="text-white">{formatDate(code.created)}</span>
                   </div>
                   <div>
                     <span className="block">Expira em</span>
                     <span className="text-white">{formatDate(code.expiresAt)}</span>
                   </div>
                   <div>
                     <span className="block">Criado por</span>
                     <span className="text-white">{code.createdBy}</span>
                   </div>
                   {code.description && (
                     <div className="md:col-span-3">
                       <span className="block">Descrição</span>
                       <span className="text-white">{code.description}</span>
                     </div>
                   )}
                 </div>
               </div>
             ))}

             {/* Paginação */}
             {totalPages > 1 && (
               <div className="flex justify-center gap-2 mt-4">
                 <button
                   onClick={() => setPage(p => Math.max(1, p - 1))}
                   disabled={page === 1}
                   className="px-3 py-1 bg-gray-700 rounded disabled:opacity-50"
                 >
                   Anterior
                 </button>
                 <span className="px-3 py-1">
                   Página {page} de {totalPages}
                 </span>
                 <button
                   onClick={() => setPage(p => Math.min(totalPages, p + 1))}
                   disabled={page === totalPages}
                   className="px-3 py-1 bg-gray-700 rounded disabled:opacity-50"
                 >
                   Próxima
                 </button>
               </div>
             )}
           </>
         )}
       </div>
     </CardContent>
   </Card>
 );
};

export default InviteCodesList;