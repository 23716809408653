import React, { useState, useEffect } from 'react';
import { useAuth } from './context/AuthContext';
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import SubscriptionRenewalModal from './components/auth/SubscriptionRenewalModal';
import Header from './components/layout/Header';
import SetupFlow from './components/setup/SetupFlow';
import AdminPanel from './components/admin/AdminPanel';
import { authService } from './services/authService';

function App() {
  const { user, loading, subscription, isAdmin } = useAuth();
  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {
    if (user && !isAdmin) {
      authService.startSubscriptionMonitoring(
        user.uid,
        (status) => {
          // O status será tratado pelo AuthContext
          // Não precisamos fazer nada aqui pois o AuthContext
          // já lidará com a exibição do modal de renovação
        }
      );
    }
    return () => authService.stopSubscriptionMonitoring();
  }, [user, isAdmin]);

  if (loading) {
    return (
      <div className="bg-gray-900 min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="bg-gray-900 min-h-screen flex items-center justify-center">
        <div id="auth-container" className="w-full max-w-md">
          {showLogin ? (
            <LoginForm onSwitchToRegister={() => setShowLogin(false)} />
          ) : (
            <RegisterForm onSwitchToLogin={() => setShowLogin(true)} />
          )}
        </div>
      </div>
    );
  }

  // Modal de renovação será mostrado quando necessário
  if (!isAdmin && subscription?.needsRenewal) {
    return <SubscriptionRenewalModal />;
  }

  return (
    <div className="bg-gray-900 min-h-screen">
      <Header />
      <div id="admin-panel" style={{ display: 'none' }}>
        <AdminPanel />
      </div>
      <main id="main-content" className="container mx-auto px-4">
        <div className="text-center mb-12">
          <div className="logo-container">
            <img src="/assets/logo_isetup1.png" alt="iSetup" className="logo-image mx-auto" />
            <div className="logo-divider"></div>
          </div>
        </div>
        <SetupFlow />
      </main>
    </div>
  );
}

export default App;