import React from 'react';
import { PHASES } from '../../utils/constants';

const CornerPhaseCard = ({ phase, onClick }) => {
  const phaseData = PHASES[phase];

  if (!phaseData) return null;

  return (
    <div className="card p-6 rounded-xl cursor-pointer" onClick={onClick}>
      <h3 className="text-xl font-semibold text-white mb-3">{phaseData.title}</h3>
      <div className="space-y-2 text-sm text-gray-400">
        {phaseData.description.map((item, index) => (
          <p key={index}>• {item}</p>
        ))}
      </div>
    </div>
  );
};

export default CornerPhaseCard;