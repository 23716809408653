import React from 'react';
import { SETUP_DATA } from '../../utils/constants';
import { CAR_SUSPENSION_DATA } from '../../utils/carSuspensionData';

const AdjustmentsList = ({ issue, phase, selectedCar}) => {
  if (!issue || (!phase && issue !== 'bumps')) return null;

  const adjustments = () => {
    // Pega os ajustes base
    const baseAdjustments = issue === 'bumps' 
      ? SETUP_DATA.adjustments[issue].direct
      : SETUP_DATA.adjustments[issue][phase];
  
    // Se não tiver carro selecionado, retorna apenas os ajustes base
    if (!selectedCar) return baseAdjustments;
  
    // Pega os dados do carro selecionado
    const carData = CAR_SUSPENSION_DATA[selectedCar];
    if (!carData) return baseAdjustments;
  
    // Adiciona recomendações específicas do carro
    return [
      ...baseAdjustments,
      {
        title: `Ajustes de Suspensão para ${selectedCar}`,
        description: "Recomendações específicas baseadas nas características do carro",
        items: [
          {
            name: "Spring Rate (Molas)",
            action: `Valores recomendados - Dianteira: ${carData.springs.recommendedRanges.front.min}-${carData.springs.recommendedRanges.front.max} ${carData.springs.recommendedRanges.front.unit}, Traseira: ${carData.springs.recommendedRanges.rear.min}-${carData.springs.recommendedRanges.rear.max} ${carData.springs.recommendedRanges.rear.unit}`,
            impact: "high",
            details: "Ajuste as molas dentro destes ranges para o melhor equilíbrio entre aerodinâmica e grip mecânico",
            related: ["Ride Height", "ARB"]
          },
          {
            name: "Anti-Roll Bar (Barras)",
            action: `Dianteira: ${carData.arb.front.description}, Traseira: ${carData.arb.rear.description}`,
            impact: "medium",
            details: "Ajuste as barras considerando o range específico deste carro",
            related: ["Spring Rate", "Ride Height"]
          }
        ]
      }
    ];
  };

  return (
    <div className="space-y-6">
      {adjustments().map((group, index) => (
        <div key={index} className="card p-6 rounded-xl">
          <div className="mb-4">
            <h3 className="text-xl font-semibold text-white">{group.title}</h3>
            <p className="text-gray-400 text-sm mt-1">{group.description}</p>
          </div>
          <div className="space-y-4">
            {group.items.map((item, itemIndex) => (
              <div key={itemIndex} className="bg-gray-800/50 p-4 rounded-lg">
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-2">
                      <h4 className="font-medium text-white">{item.name}</h4>
                      <span className={`impact-badge impact-${item.impact}`}>
                        {item.impact === 'low' ? 'Baixo' : 
                         item.impact === 'medium' ? 'Médio' : 'Alto'}
                      </span>
                    </div>
                    <p className="text-gray-400 text-sm mt-1">{item.action}</p>
                    <p className="text-gray-500 text-sm mt-2">{item.details}</p>
                    {item.related && (
                      <div className="mt-2 text-sm text-gray-400">
                        Ajustes relacionados: {item.related.join(', ')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdjustmentsList;