import React from 'react';
import { ISSUES } from '../../utils/constants';

const CarIssueCard = ({ type, onClick }) => {
  const issue = ISSUES[type];

  if (!issue) return null;

  return (
    <div className="card p-6 rounded-xl cursor-pointer" onClick={onClick}>
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-xl font-semibold text-white">{issue.title}</h3>
        <span className={`px-3 py-1 rounded-full text-sm ${issue.label.className}`}>
          {issue.label.text}
        </span>
      </div>
      <p className="text-gray-400 mb-4">{issue.description}</p>
      <div className="grid grid-cols-2 gap-2 text-sm text-gray-500">
        {issue.impacts.map((impact, index) => (
          <span key={index}>• {impact}</span>
        ))}
      </div>
    </div>
  );
};

export default CarIssueCard;