import { CAR_SUSPENSION_DATA, getCarSuspensionRecommendation } from './carSuspensionData';
export const IMPACT_LEVELS = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high'
};

export const IMPACT_LABELS = {
  [IMPACT_LEVELS.LOW]: 'Baixo',
  [IMPACT_LEVELS.MEDIUM]: 'Médio',
  [IMPACT_LEVELS.HIGH]: 'Alto'
};

export const TRACKS_DOWNFORCE = [
  { name: "Autodromo Jose Carlos Pace", level: "High/Medium" },
  { name: "Autodromo Nazionale Monza", level: "Low/Medium" },
  { name: "Brands Hatch Circuit", level: "High" },
  { name: "Circuit de Barcelona Catalunya", level: "High" },
  { name: "Circuit de Nevers Magny-Cours", level: "High/Medium" },
  { name: "Circuit de Spa-Francorchamps", level: "Medium" },
  { name: "Circuit des 24 Heures Du Mans", level: "Low" },
  { name: "Daytona International Speedway", level: "Low/Medium" },
  { name: "Detroit Grand Prix at Belle Isle", level: "High" },
  { name: "Fuji International Speedway", level: "High/Medium" },
  { name: "Hungaroring", level: "High" },
  { name: "Indianapolis Motor Speedway", level: "Medium" },
  { name: "Lime Rock Park", level: "High" },
  { name: "Long Beach Street Circuit", level: "High" },
  { name: "Motorsports Arena Oschersleben", level: "High" },
  { name: "Mount Panorama Circuit", level: "High/Medium" },
  { name: "Nürburgring Grand-Prix-Strecke", level: "High" },
  { name: "Okayama International Circuit", level: "High" },
  { name: "Road America", level: "High/Medium" },
  { name: "Sebring International Raceway", level: "High" },
  { name: "Silverstone Circuit", level: "High/Medium" },
  { name: "Sonoma Raceway", level: "High" },
  { name: "Virginia International Raceway", level: "High/Medium" },
  { name: "Watkins Glen International", level: "High/Medium" },
  { name: "WeatherTech Raceway at Laguna Seca", level: "High" },
  // Novas pistas
  { name: "Algarve International Circuit", level: "High" },
  { name: "Autodromo Internazionale Enzo e Dino Ferrari", level: "High" },
  { name: "Charlotte Motor Speedway", level: "High" },
  { name: "Chicago Street Course", level: "High" },
  { name: "Circuit de Lédenon", level: "High" },
  { name: "Circuit Gilles Villeneuve", level: "High" },
  { name: "Circuit Zolder", level: "High" },
  { name: "Circuito de Navarra", level: "High" },
  { name: "Donington Park Racing Circuit", level: "High" },
  { name: "Hockenheimring Baden-Württemberg", level: "High" },
  { name: "Nürburgring Combined", level: "High" },
  { name: "Nürburgring Nordschleife", level: "High" },
  { name: "Oran Park Raceway", level: "High" },
  { name: "Oulton Park Circuit", level: "High" },
  { name: "Phillip Island Circuit", level: "High" },
  { name: "Red Bull Ring", level: "High" },
  { name: "Road Atlanta", level: "High" },
  { name: "Rudskogen Motorsenter", level: "High" },
  { name: "Snetterton Circuit", level: "High" },
  { name: "Summit Point Raceway", level: "High" },
  { name: "Suzuka International Racing Course", level: "High" },
  { name: "Tsukuba Circuit", level: "High" },
  { name: "Winton Motor Raceway", level: "High" },
  { name: "Cadwell Park Circuit", level: "High" },
  { name: "Willow Springs International Raceway", level: "High" },
  { name: "Slinger Speedway", level: "High" },
  { name: "Sandown International Motor Raceway", level: "High" },
  { name: "Sachsenring", level: "High" },
  { name: "New Jersey Motorsports Park", level: "High" },
  { name: "New Hampshire Motor Speedway", level: "High" },
  { name: "Mount Washington Hillclimb", level: "High" },
  { name: "MotorLand Aragón", level: "High" },
  { name: "Mobility Resort Motegi", level: "High" },
  { name: "Misano World Circuit Marco Simoncelli", level: "High" },
  { name: "Mid-Ohio Sports Car Course", level: "High" },
  { name: "Las Vegas Motor Speedway", level: "High" },
  { name: "Lånkebanen (Hell RX)", level: "High" },
  { name: "Knockhill Racing Circuit", level: "High" },
  { name: "Kansas Speedway", level: "High" },
  { name: "Irwindale Speedway", level: "High" },
  { name: "Iowa Speedway", level: "High" },
  { name: "Homestead Miami Speedway", level: "High" },
  { name: "Circuito de Jerez - Ángel Nieto", level: "High" },
  { name: "Circuit Zandvoort", level: "High" },
  { name: "Circuit of the Americas", level: "High" },
  { name: "Canadian Tire Motorsports Park", level: "High" },
  { name: "Barber Motorsports Park", level: "High" },
  { name: "Autodromo Internazionale del Mugello", level: "High" },
  { name: "Auto Club Speedway", level: "High" },
  { name: "Atlanta Motor Speedway", level: "High" }
 ];

export const DOWNFORCE_SPEED_RECOMMENDATIONS = [
  { speed: "Abaixo de 250 km/h (155 mph)", level: "High Downforce" },
  { speed: "250-270 km/h (155 mph - 167 mph)", level: "Medium" },
  { speed: "Acima de 270 km/h (167 mph)", level: "Low to Minimum Downforce" }
];
// src/utils/constants.js

export const GT3_CARS = {
CORVETTE: {
  id: 'corvette',
  name: 'Corvette Z06 GT3',
  adjustments: {
    frontPerDegree: 1.5,  // mm por grau
    rearPerDegree: 4.0,   // mm por grau
  },
  wingRange: {
    min: 0.5,
    max: 9.5,
  }
},
BMW: {
  id: 'bmw',
  name: 'BMW M4 GT3',
  adjustments: {
    frontPerDegree: 1.5,
    rearPerDegree: 4.5,
  },
  wingRange: {
    min: -2,
    max: 6,
  }
},
AUDI: {
  id: 'audi',
  name: 'Audi R8 EVO II GT3',
  adjustments: {
    frontPerDegree: 2.4,
    rearPerDegree: 7.2,
  },
  wingRange: {
    min: 2,
    max: 7,
  }
},
FERRARI: {
  id: 'ferrari',
  name: 'Ferrari 296 GT3',
  adjustments: {
    frontPerDegree: 1.0,
    rearPerDegree: 3.0,
  },
  wingRange: {
    min: -2,
    max: 10,
  }
},
MUSTANG: {
  id: 'mustang',
  name: 'Mustang GT3',
  adjustments: {
    frontPerDegree: 1.5,
    rearPerDegree: 4.0,
  },
  wingRange: {
    min: 0.5,
    max: 9,
  }
},
MCLAREN: {
  id: 'mclaren',
  name: 'McLaren 720s GT3 EVO',
  adjustments: {
    frontPerDegree: 1.5,
    rearPerDegree: 4.5,
  },
  wingRange: {
    min: 0.5,
    max: 10.5,
  }
}
,
LAMBORGHINI: {
  id: 'lamborghini',
  name: 'Lamborghini Huracan GT3 EVO',
  adjustments: {
    frontPerDegree: 1.2,
    rearPerDegree: 3.6,
  },
  wingRange: {
    min: 2,
    max: 12,
  }
}
,
MERCEDES: {
  id: 'mercedes',
  name: 'Mercedes AMG GT3 2020',
  adjustments: {
    frontPerDegree: 1.2,
    rearPerDegree: 3.6,
  },
  wingRange: {
    min: -1,
    max: 9,
  }
}
,
PORSCHE: {
  id: 'porsche',
  name: 'Porsche 911 GT3R 992',
  adjustments: {
    frontPerDegree: 2,
    rearPerDegree: 5.5,
  },
  wingRange: {
    min: 5,
    max: 12,
  }
}
};
export const ISSUES = {
  oversteer: {
    title: 'Oversteer',
    label: {
      text: 'Instabilidade',
      className: 'bg-red-500/20 text-red-300'
    },
    description: 'Traseira escapa, perda de tração traseira',
    impacts: [
      'Rotação excessiva',
      'Instabilidade na saída',
      'Perda de tração',
      'Correções frequentes'
    ]
  },
  understeer: {
    title: 'Understeer',
    label: {
      text: 'Subesterço',
      className: 'bg-blue-500/20 text-blue-300'
    },
    description: 'Carro não faz a curva, empurra para fora',
    impacts: [
      'Frente empurra',
      'Raio maior que ideal',
      'Falta de grip frontal',
      'Esterço excessivo'
    ]
  },
  bumps: {
    title: 'Car Edgy Over Bumps',
    label: {
      text: 'Instabilidade',
      className: 'bg-purple-500/20 text-purple-300'
    },
    description: 'Carro instável sobre lombadas e zebras',
    impacts: [
      'Perda de contato',
      'Oscilações',
      'Bouncing',
      'Instabilidade em zebras'
    ]
  }
};

export const PHASES = {
  entry: {
    title: 'Entrada',
    description: [
      'Fase de frenagem',
      'Redução de velocidade',
      'Rotação inicial'
    ]
  },
  mid: {
    title: 'Meio',
    description: [
      'Ápice da curva',
      'Velocidade mínima',
      'Balanço máximo'
    ]
  },
  exit: {
    title: 'Saída',
    description: [
      'Aplicação de potência',
      'Aceleração',
      'Tração'
    ]
  }
};

export const SETUP_DATA = {
  adjustments: {
    oversteer: {
      entry: [
        {
          title: "Ajustes de Estabilidade Iniciais",
          description: "Ajustes de baixo impacto para estabilidade na entrada",
          items: [
            {
              name: "Brake Bias",
              action: "Aumentar 1-2%",
              impact: "low",
              details: "Reduz rotação na frenagem",
              related: ["Front Bump Stops"]
            },
            {
              name: "Front Toe",
              action: "Reduzir toe-out em 0.1mm",
              impact: "low",
              details: "Aumenta estabilidade inicial",
              related: ["Rear Toe"]
            }
          ]
        },
        {
          title: "Ajustes de Amortecedores",
          description: "Controle de transferência de peso",
          items: [
            {
              name: "Front LSC",
              action: "Aumentar 2-3 clicks",
              impact: "medium",
              details: "Melhor controle de pitch"
            },
            {
              name: "Front Bump Rubber Gap",
              action: "Reduzir 1mm",
              impact: "medium",
              details: "Previne mergulho excessivo"
            },
            {
              name: "Front LSR",
              action: "Aumentar 1-2 clicks",
              impact: "medium",
              details: "Mantém carga no eixo dianteiro"
            }
          ]
        }
      ],
      mid: [
        {
          title: "Ajustes de Equilíbrio",
          description: "Controle de rolagem e balanço",
          items: [
            {
              name: "Front ARB",
              action: "Aumentar 1 posição",
              impact: "high",
              details: "Reduz rolagem em curva"
            },
            {
              name: "Rear Toe",
              action: "Aumentar toe-in 0.1mm",
              impact: "medium",
              details: "Mais estabilidade em curvas longas"
            }
          ]
        }
      ],
      exit: [
        {
          title: "Ajustes de Tração",
          description: "Melhorias de tração na saída",
          items: [
            {
              name: "Diff Preload",
              action: "Reduzir 20 Nm",
              impact: "high",
              details: "Melhor tração na saída"
            },
            {
              name: "Rear LSC",
              action: "Aumentar 2-3 clicks",
              impact: "medium",
              details: "Melhor controle de squat"
            }
          ]
        }
      ]
    },
    understeer: {
      entry: [
        {
          title: "Ajustes de Entrada",
          description: "Melhorias de rotação inicial",
          items: [
            {
              name: "Brake Bias",
              action: "Reduzir 1-1.5%",
              impact: "low",
              details: "Ajuda na rotação inicial"
            },
            {
              name: "Front Toe",
              action: "Aumentar toe-out 0.2mm",
              impact: "medium",
              details: "Mais agressividade na entrada"
            }
          ]
        }
      ],
      mid: [
        {
          title: "Ajustes de Barras",
          description: "Controle de rolagem",
          items: [
            {
              name: "Front ARB",
              action: "Reduzir 1-2 posições",
              impact: "high",
              details: "Mais grip mecânico dianteiro"
            },
            {
              name: "Rear ARB",
              action: "Aumentar 1 posição",
              impact: "medium",
              details: "Ajuda na rotação"
            }
          ]
        }
      ],
      exit: [
        {
          title: "Ajustes de Diferencial",
          description: "Controle de tração",
          items: [
            {
              name: "Diff Preload",
              action: "Aumentar 30 Nm",
              impact: "high",
              details: "Ajuda na rotação na saída"
            },
            {
              name: "Friction Faces",
              action: "Aumentar 2 faces",
              impact: "medium",
              details: "Mais rotação na potência"
            }
          ]
        }
      ]
    },
    bumps: {
      direct: [
        {
          title: "Ajustes de Bump Stops",
          description: "Controle de curso da suspensão",
          items: [
            {
              name: "Front Bump Rubber Gap",
              action: "Aumentar 1-2mm",
              impact: "high",
              details: "Permite mais curso antes do bump stop",
              related: ["Front HSC"]
            },
            {
              name: "Rear Bump Rubber Gap",
              action: "Aumentar 1-2mm",
              impact: "high",
              details: "Mais absorção de impactos",
              related: ["Rear HSC"]
            }
          ]
        },
        {
          title: "Ajustes de Amortecedores",
          description: "Controle de impactos e recuperação",
          items: [
            {
              name: "Front HSC",
              action: "Reduzir 2-3 clicks",
              impact: "medium",
              details: "Melhor absorção de impactos"
            },
            {
              name: "Rear HSC",
              action: "Reduzir 2-3 clicks",
              impact: "medium",
              details: "Suaviza impactos traseiros"
            },
            {
              name: "Front HSR",
              action: "Aumentar 1-2 clicks",
              impact: "medium",
              details: "Controle de ressaltos"
            },
            {
              name: "Rear HSR",
              action: "Aumentar 1-2 clicks",
              impact: "medium",
              details: "Melhor recuperação após impactos"
            }
          ]
        },
        {
          title: "Ajustes de Plataforma",
          description: "Estabilidade geral do chassis",
          items: [
            {
              name: "Front ARB",
              action: "Reduzir 1 posição",
              impact: "medium",
              details: "Permite mais independência da suspensão"
            },
            {
              name: "Rear ARB",
              action: "Reduzir 1 posição",
              impact: "medium",
              details: "Mais compliance sobre zebras"
            },
            {
              name: "Ride Height",
              action: "Aumentar 1-2mm",
              impact: "low",
              details: "Mais margem para absorção",
              related: ["Bump Rubber Gap"]
            }
          ]
        }
      ]
    }
  }
};