export const formatTime = (milliseconds) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  
    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0) parts.push(`${seconds}s`);
  
    return parts.join(' ') || '0s';
  };
  
  export const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };
  
  export const calculateFuel = (lapTimeMin, lapTimeSec, raceLength, fuelPerLap) => {
    const lapTimeInMinutes = lapTimeMin + (lapTimeSec / 60);
    const totalLaps = Math.ceil(raceLength / lapTimeInMinutes);
    const minFuel = totalLaps * fuelPerLap;
    const recommendedFuel = minFuel * 1.08; // 8% margin
  
    return {
      totalLaps,
      minFuel: minFuel.toFixed(1),
      recommendedFuel: recommendedFuel.toFixed(1)
    };
  };
  
  export const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  
  export const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };
  
  export const getErrorMessage = (error) => {
    const errorMessages = {
      'auth/invalid-email': 'Email inválido',
      'auth/user-disabled': 'Esta conta foi desativada',
      'auth/user-not-found': 'Email ou senha incorretos',
      'auth/wrong-password': 'Email ou senha incorretos',
      'auth/email-already-in-use': 'Este email já está em uso',
      'auth/operation-not-allowed': 'Operação não permitida',
      'auth/weak-password': 'Senha muito fraca',
      'auth/too-many-requests': 'Muitas tentativas. Tente novamente mais tarde',
      'invite-code/invalid': 'Código de convite inválido',
      'invite-code/expired': 'Código de convite expirado',
      'invite-code/max-uses': 'Código de convite atingiu o limite de usos',
    };
  
    if (typeof error === 'string') return error;
    return errorMessages[error.code] || 'Ocorreu um erro inesperado';
  };
  
  export const generateInviteCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };