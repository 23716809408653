import { auth, database } from './firebase';

class AuthService {
  constructor() {
    this.subscriptionCheckInterval = null;
    this.SUBSCRIPTION_CHECK_INTERVAL = 5000;
    this.RENEWAL_GRACE_PERIOD = 5 * 60 * 1000;
    this.hasShownWarning = false;
  }
  startSubscriptionMonitoring(userId, onExpired, onWarning) {
    this.stopSubscriptionMonitoring();
    
    if (!userId) return;
    
    const checkSubscription = async () => {
      try {
        const isAdmin = await this.checkAdminStatus(userId);
        if (isAdmin) return;

        const status = await this.checkSubscriptionStatus(userId);
        const now = Date.now();
        
        // Se não estiver ativo, notifica imediatamente
        if (!status.isActive) {
          onExpired?.(status);
          return;
        }
        
        // Verifica se está próximo de expirar
        const timeUntilExpiry = status.expiresAt - now;
        if (timeUntilExpiry < this.RENEWAL_GRACE_PERIOD) {
          const message = `Sua assinatura expira em ${Math.ceil(timeUntilExpiry / (1000 * 60))} minutos`;
          onWarning?.(message, timeUntilExpiry);
        }
      } catch (error) {
        console.error('Erro no monitoramento da assinatura:', error);
      }
    };

    checkSubscription();
    this.subscriptionCheckInterval = setInterval(checkSubscription, this.SUBSCRIPTION_CHECK_INTERVAL);
  }
  async resendVerificationEmail(email, password) {
    if (!email || !password) {
      throw new Error('Email e senha são obrigatórios');
    }
  
    try {
      const tempUserCredential = await auth.signInWithEmailAndPassword(email, password);
      const user = tempUserCredential.user;
  
      await user.sendEmailVerification();
  
      await database.ref(`users/${user.uid}`).update({
        emailVerificationSentAt: Date.now()
      });
  
      await auth.signOut();
  
      return true;
    } catch (error) {
      console.error('Erro no reenvio:', error);
      
      if (error.code === 'auth/wrong-password') {
        throw new Error('Credenciais inválidas');
      }
      if (error.code === 'auth/user-not-found') {
        throw new Error('Usuário não encontrado');
      }
      if (error.code === 'auth/too-many-requests') {
        throw new Error('Muitas tentativas. Aguarde alguns minutos e tente novamente.');
      }
      
      throw new Error('Não foi possível reenviar o email. Tente novamente mais tarde.');
    } finally {
      try {
        await auth.signOut();
      } catch (e) {
        console.error('Erro ao fazer logout:', e);
      }
    }
  }

  async validateInviteCode(code) {
    if (!code) throw new Error('Código de convite é obrigatório');
    
    try {
      const snapshot = await database
        .ref('inviteCodes')
        .child(code.toUpperCase())
        .once('value');
      
      const inviteData = snapshot.val();
      
      if (!inviteData) {
        throw new Error('Código de convite inválido');
      }
      
      if (inviteData.usedCount >= inviteData.maxUses) {
        throw new Error('Código de convite já atingiu o limite máximo de usos');
      }
      
      const now = Date.now();
      if (inviteData.expiresAt < now) {
        throw new Error('Código de convite expirado');
      }
  
      return inviteData;
    } catch (error) {
      console.error('Erro na validação do código:', error);
      throw error;
    }
  }

  async checkSubscriptionStatus(userId) {
    try {
      const snapshot = await database
        .ref('users')
        .child(userId)
        .child('subscription')
        .once('value');
      
      const subscription = snapshot.val();
      const now = Date.now();
      
      if (!subscription) {
        return { 
          isActive: false, 
          needsRenewal: true,
          expiresAt: now,
          status: 'inactive'
        };
      }

      const isExpired = subscription.expiresAt < now;
      const isActive = subscription.status === 'active' && !isExpired;

      const response = {
        ...subscription,
        isActive,
        needsRenewal: isExpired || !isActive,
        status: isExpired ? 'expired' : subscription.status,
        expiresAt: subscription.expiresAt,
        graceTimeLeft: isExpired ? 0 : subscription.expiresAt - now
      };

      console.log('Status da assinatura:', {
        userId,
        now: new Date(now).toLocaleString(),
        expiresAt: new Date(subscription.expiresAt).toLocaleString(),
        originalStatus: subscription.status,
        isExpired,
        isActive: response.isActive,
        needsRenewal: response.needsRenewal
      });

      return response;
    } catch (error) {
      console.error('Erro ao verificar assinatura:', error);
      return {
        isActive: false,
        needsRenewal: true,
        status: 'error',
        expiresAt: Date.now(),
        error: error.message
      };
    }
  }

  async register(email, password, name, inviteCode) {
    let userCredential = null;
    
    try {
      const inviteData = await this.validateInviteCode(inviteCode);
      const now = Date.now();
      
      userCredential = await auth.createUserWithEmailAndPassword(email, password);
      await userCredential.user.updateProfile({ displayName: name });
      
      const expiresAt = inviteData.isTestMode
        ? now + (parseInt(inviteData.subscriptionSeconds) || 60) * 1000
        : now + (parseInt(inviteData.subscriptionDays) || 30) * 24 * 60 * 60 * 1000;
      
      const userData = {
        email,
        name,
        createdAt: now,
        updatedAt: now,
        subscription: {
          status: 'active',
          startedAt: now,
          expiresAt: expiresAt,
          updatedAt: now,
          lastRenewalCode: inviteCode.toUpperCase(),
          inviteCode: inviteCode.toUpperCase()
        }
      };

      await database.ref(`users/${userCredential.user.uid}`).set(userData);

      await database.ref(`inviteCodes/${inviteCode.toUpperCase()}`).update({
        usedCount: (inviteData.usedCount || 0) + 1,
        lastUsedBy: email,
        lastUsedAt: now
      });

      await userCredential.user.sendEmailVerification();
      await auth.signOut();
      
      return userCredential;
    } catch (error) {
      if (userCredential?.user) {
        try {
          await userCredential.user.delete();
        } catch (deleteError) {
          console.error('Erro ao limpar usuário:', deleteError);
        }
      }
      console.error('Erro no registro:', error);
      throw error;
    }
  }


  async renewSubscription(userId, code) {
    try {
      if (!auth.currentUser || auth.currentUser.uid !== userId) {
        throw new Error('Não autorizado');
      }

      const inviteData = await this.validateInviteCode(code);
      const now = Date.now();

      const subscriptionDuration = inviteData.isTestMode
        ? (parseInt(inviteData.subscriptionSeconds) || 60) * 1000
        : (parseInt(inviteData.subscriptionDays) || 30) * 24 * 60 * 60 * 1000;

      const newExpirationDate = now + subscriptionDuration;

      await database.ref(`inviteCodes/${code.toUpperCase()}`).update({
        usedCount: (inviteData.usedCount || 0) + 1,
        lastUsedBy: auth.currentUser.email,
        lastUsedAt: now
      });

      const subscriptionUpdate = {
        status: 'active',
        expiresAt: newExpirationDate,
        updatedAt: now,
        lastRenewalCode: code.toUpperCase(),
        renewedAt: now
      };

      await database.ref(`users/${userId}/subscription`).update(subscriptionUpdate);

      return {
        success: true,
        ...subscriptionUpdate
      };

    } catch (error) {
      console.error('Erro na renovação:', error);
      throw error;
    }
  }

  async login(email, password) {
    const maxRetries = 3;
    let lastError = null;
  
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const result = await auth.signInWithEmailAndPassword(email, password);
        return result;
      } catch (error) {
        lastError = error;
        if (error.code === 'auth/network-request-failed') {
          await new Promise(resolve => setTimeout(resolve, 2000));
          continue;
        }
        throw error;
      }
    }
  
    throw lastError;
  }

  clearLogoutTimer() {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
      this.logoutTimeout = null;
    }
  }
  
  startSubscriptionMonitoring(userId, onExpired) {
    this.stopSubscriptionMonitoring();
    
    if (!userId) return;
    
    const checkSubscription = async () => {
      try {
        const isAdmin = await this.checkAdminStatus(userId);
        if (isAdmin) return;

        const status = await this.checkSubscriptionStatus(userId);
        
        if (!status.isActive && !this.hasShownWarning) {
          this.hasShownWarning = true;
          // Apenas atualiza o estado, sem alertas ou redirecionamentos
          onExpired?.(status);
        }
      } catch (error) {
        console.error('Erro no monitoramento da assinatura:', error);
      }
    };

    checkSubscription();
    this.subscriptionCheckInterval = setInterval(checkSubscription, this.SUBSCRIPTION_CHECK_INTERVAL);
  }

  stopSubscriptionMonitoring() {
    if (this.subscriptionCheckInterval) {
      clearInterval(this.subscriptionCheckInterval);
      this.subscriptionCheckInterval = null;
    }
  }

  
  formatFirebaseError(error) {
    const errorMessages = {
      'auth/user-not-found': 'Usuário não encontrado.',
      'auth/wrong-password': 'Senha incorreta.',
      'auth/invalid-email': 'Email inválido.',
      'auth/user-disabled': 'Esta conta foi desativada.',
      'auth/email-already-in-use': 'Este email já está cadastrado. Por favor, utilize outro email ou faça login.',
      'auth/operation-not-allowed': 'Operação não permitida.',
      'auth/weak-password': 'A senha é muito fraca.',
      'auth/too-many-requests': 'Muitas tentativas. Tente novamente mais tarde.',
      'auth/email-not-verified': 'Por favor, verifique seu email antes de fazer login.'
    };

    const message = errorMessages[error.code] || error.message;
    const formattedError = new Error(message);
    formattedError.code = error.code;
    return formattedError;
  }

  
  async checkAdminStatus(userId) {
    try {
      const snapshot = await database
        .ref('admins')
        .child(userId)
        .once('value');
      
      return snapshot.exists() && snapshot.val() === true;
    } catch (error) {
      console.error('Erro ao verificar status de admin:', error);
      return false;
    }
  }

  async handleExpiredSubscription() {
    this.hasShownWarning = true;
  }


  async logout() {
    try {
      this.stopSubscriptionMonitoring();
      this.hasShownWarning = false;
      await auth.signOut();
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
      throw error;
    }
  }



  async resetPassword(email) {
    try {
      await auth.sendPasswordResetEmail(email);
    } catch (error) {
      console.error('Erro ao redefinir senha:', error);
      throw error;
    }
  }
}

export const authService = new AuthService();