import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { LogOut, Settings, User } from 'lucide-react';

const Header = () => {
  const { user, logout, isAdmin } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const showAdminPanel = () => {
    const adminPanel = document.getElementById('admin-panel');
    const mainContent = document.getElementById('main-content');
    if (adminPanel && mainContent) {
      adminPanel.style.display = 'block';
      mainContent.style.display = 'none';
    }
  };

  const showMainContent = () => {
    const adminPanel = document.getElementById('admin-panel');
    const mainContent = document.getElementById('main-content');
    if (adminPanel && mainContent) {
      adminPanel.style.display = 'none';
      mainContent.style.display = 'block';
    }
  };

  return (
    <header className="bg-gray-800/50 backdrop-blur-sm shadow-lg">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {/* Logo minimalista */}
            <div 
  className="flex items-center cursor-pointer group"
  onClick={showMainContent}
>
  {/* Meia engrenagem */}
  <div className="text-blue-500 mr-1 overflow-hidden" style={{ width: '12px' }}>
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2.5" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className="group-hover:rotate-45 transition-transform duration-300"
    >
      <circle cx="12" cy="12" r="3"></circle>
      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
    </svg>
  </div>
              {/* Texto "Setup" estilizado */}
              <span className="text-lg font-medium text-white">
    <span className="text-white">i</span>Setup
  </span>
            </div>

            {/* Badge de Admin */}
            {isAdmin && (
              <button
                onClick={showAdminPanel}
                className="flex items-center space-x-2 px-3 py-1.5 bg-blue-500/10 text-blue-400 rounded-lg hover:bg-blue-500/20 transition-colors text-sm font-medium"
              >
                <Settings size={16} />
                <span>Admin</span>
              </button>
            )}
          </div>
          
          <div className="flex items-center space-x-6">
            {/* Info do usuário */}
            <div className="flex items-center space-x-2 text-gray-300">
              <User size={16} className="text-gray-400" />
              <span className="text-sm font-medium">
                {user.displayName || user.email}
              </span>
            </div>

            {/* Botão de logout */}
            <button 
              onClick={handleLogout}
              className="flex items-center space-x-2 px-3 py-1.5 text-gray-400 hover:text-gray-200 transition-colors"
            >
              <LogOut size={16} />
              <span className="text-sm font-medium">Sair</span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;