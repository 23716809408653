import React, { useState } from 'react';
import { Card } from '../ui/Card';
import { Calculator, Clock, Fuel, Info } from 'lucide-react';

const FuelCalculator = () => {
  const [lapTimeMin, setLapTimeMin] = useState('');
  const [lapTimeSec, setLapTimeSec] = useState('');
  const [raceLength, setRaceLength] = useState('');
  const [fuelPerLap, setFuelPerLap] = useState('');
  const [result, setResult] = useState(null);

  const calculateFuel = () => {
    const lapTimeInMinutes = parseInt(lapTimeMin) + (parseInt(lapTimeSec) / 60);
    const totalLaps = Math.ceil(parseInt(raceLength) / lapTimeInMinutes);
    const minFuel = totalLaps * parseFloat(fuelPerLap);
    const recommendedFuel = minFuel * 1.08; // 8% margin

    setResult({
      totalLaps,
      minFuel: minFuel.toFixed(1),
      recommendedFuel: recommendedFuel.toFixed(1)
    });
  };

  return (
    <div className="bg-gray-800/50 rounded-lg p-4 mt-4">
      <div className="flex items-center space-x-2 mb-4">
        <Calculator className="h-5 w-5 text-blue-500" />
        <h3 className="text-lg font-medium text-white">Calculadora de Combustível</h3>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm text-gray-300 mb-1">Tempo de Volta</label>
          <div className="flex space-x-2">
            <div className="flex-1">
              <input
                type="number"
                min="0"
                value={lapTimeMin}
                onChange={(e) => setLapTimeMin(e.target.value)}
                placeholder="Min"
                className="w-full px-3 py-1.5 bg-gray-700 rounded text-white text-sm"
              />
            </div>
            <div className="flex-1">
              <input
                type="number"
                min="0"
                max="59"
                value={lapTimeSec}
                onChange={(e) => setLapTimeSec(e.target.value)}
                placeholder="Sec"
                className="w-full px-3 py-1.5 bg-gray-700 rounded text-white text-sm"
              />
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm text-gray-300 mb-1">Duração da Corrida (min)</label>
          <input
            type="number"
            min="0"
            value={raceLength}
            onChange={(e) => setRaceLength(e.target.value)}
            className="w-full px-3 py-1.5 bg-gray-700 rounded text-white text-sm"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm text-gray-300 mb-1">Combustível por Volta</label>
        <div className="flex space-x-2 items-center">
          <input
            type="number"
            min="0"
            step="0.1"
            value={fuelPerLap}
            onChange={(e) => setFuelPerLap(e.target.value)}
            className="flex-1 px-3 py-1.5 bg-gray-700 rounded text-white text-sm"
          />
          <button
            onClick={calculateFuel}
            disabled={!lapTimeMin || !lapTimeSec || !raceLength || !fuelPerLap}
            className="px-4 py-1.5 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed text-sm"
          >
            Calcular
          </button>
        </div>
      </div>

      {result && (
        <div className="bg-gray-700/50 rounded p-3 space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-300">Total de Voltas:</span>
            <span className="text-white">{result.totalLaps}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-300">Combustível Mínimo:</span>
            <span className="text-white">{result.minFuel}L</span>
          </div>
          <div className="flex justify-between text-sm font-medium">
            <span className="text-gray-300">Combustível Recomendado:</span>
            <span className="text-green-400">{result.recommendedFuel}L</span>
          </div>
          <div className="text-xs text-gray-400 flex items-center mt-2">
            <Info className="h-3 w-3 mr-1" />
            Margem de segurança de 8% aplicada
          </div>
        </div>
      )}
    </div>
  );
};

export default FuelCalculator;