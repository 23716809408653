import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, database } from '../services/firebase';
import { authService } from '../services/authService';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);

  const checkSubscription = async (userId = null) => {
    try {
      const currentUserId = userId || user?.uid;
      if (!currentUserId) return;

      const status = await authService.checkSubscriptionStatus(currentUserId);
      const now = Date.now();
      const isExpired = status.expiresAt < now;
      
      setSubscription({
        ...status,
        isActive: status.status === 'active' && !isExpired,
        needsRenewal: isExpired || status.status !== 'active'
      });
      
      return status;
    } catch (error) {
      console.error('Erro ao verificar assinatura:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (!user || isAdmin) return;

    console.log('Iniciando monitoramento de assinatura para:', user.uid);
    
    const subscriptionRef = database.ref(`users/${user.uid}/subscription`);

    const handleSubscriptionChange = async (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        console.log('Nenhum dado de assinatura encontrado');
        return;
      }

      const now = Date.now();
      const isExpired = data.expiresAt < now;
      
      setSubscription(prev => ({
        ...prev,
        ...data,
        isActive: data.status === 'active' && !isExpired,
        needsRenewal: isExpired || data.status !== 'active'
      }));

      if (isExpired || data.status !== 'active') {
        setWarningMessage('Sua assinatura expirou ou está inativa');
        setTimeLeft(0);
      }
    };

    subscriptionRef.on('value', handleSubscriptionChange);

    const checkInterval = setInterval(() => {
      checkSubscription();
    }, 10000);

    return () => {
      subscriptionRef.off('value', handleSubscriptionChange);
      clearInterval(checkInterval);
      authService.stopSubscriptionMonitoring();
    };
  }, [user, isAdmin]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          console.log('Usuário autenticado:', user.email);
          
          if (!user.emailVerified) {
            console.log('Email não verificado, fazendo logout');
            await authService.logout();
            setUser(null);
            setLoading(false);
            return;
          }

          const adminStatus = await authService.checkAdminStatus(user.uid);
          setIsAdmin(adminStatus);

          if (!adminStatus) {
            await checkSubscription(user.uid);
          }

          setUser(user);
        } else {
          console.log('Usuário deslogado');
          setUser(null);
          setIsAdmin(false);
          setSubscription(null);
          setWarningMessage('');
          setTimeLeft(null);
        }
      } catch (error) {
        console.error('Erro ao verificar status:', error);
        await authService.logout();
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async (email, password) => {
    try {
      const result = await authService.login(email, password);
      
      if (!result.user.emailVerified) {
        throw new Error('Por favor, verifique seu email antes de fazer login.');
      }

      return result;
    } catch (error) {
      throw error;
    }
  };

  const register = async (email, password, name, inviteCode) => {
    try {
      console.log('Tentando registro:', email);
      const result = await authService.register(email, password, name, inviteCode);
      return result;
    } catch (error) {
      console.error('Erro no registro:', error);
      throw error;
    }
  };

  const renewSubscription = async (inviteCode) => {
    if (!user) throw new Error('Usuário não autenticado');

    try {
      const newStatus = await authService.renewSubscription(user.uid, inviteCode);
      setSubscription({
        ...newStatus,
        needsRenewal: false,
        isActive: true
      });
      await checkSubscription(user.uid);
      return newStatus;
    } catch (error) {
      console.error('Erro na renovação:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await authService.logout();
    } catch (error) {
      throw error;
    }
  };

  const value = {
    user,
    isAdmin,
    loading,
    subscription,
    warningMessage,
    timeLeft,
    login,
    register,
    renewSubscription,
    logout,
    checkSubscription
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};