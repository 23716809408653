import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { authService } from '../../services/authService';
import EmailVerificationModal from './EmailVerificationModal';

const RegisterForm = ({ onSwitchToLogin }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    inviteCode: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [registeredPassword, setRegisteredPassword] = useState('');
  const { register } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'inviteCode' ? value.toUpperCase() : value
    }));

    // Limpar mensagem de erro quando usuário começa a digitar
    if (error) {
      setError('');
    }
  };

  const errorMessages = {
    'auth/email-already-in-use': 'Este email já está cadastrado. Por favor, utilize outro email ou faça login.',
    'auth/invalid-email': 'O email fornecido não é válido.',
    'auth/weak-password': 'A senha deve ter pelo menos 6 caracteres.',
    'auth/operation-not-allowed': 'Não foi possível criar a conta no momento. Tente novamente mais tarde.',
    'Código de convite inválido': 'Código de convite inválido. Verifique o código e tente novamente.',
    'Código de convite já atingiu o limite máximo de usos': 'Este código de convite já atingiu o limite máximo de usos.',
    'Código de convite expirado': 'Este código de convite está expirado.',
    'validation/name-required': 'O nome é obrigatório.',
    'validation/email-required': 'O email é obrigatório.',
    'validation/password-required': 'A senha é obrigatória.',
    'validation/password-mismatch': 'As senhas não coincidem.',
    'validation/invite-code-required': 'O código de convite é obrigatório.'
  };

  const validateForm = () => {
    if (!formData.name.trim()) {
      setError(errorMessages['validation/name-required']);
      return false;
    }
    if (!formData.email.trim()) {
      setError(errorMessages['validation/email-required']);
      return false;
    }

    if (!formData.password) {
      setError(errorMessages['validation/password-required']);
      return false;
    }

    if (formData.password.length < 6) {
      setError(errorMessages['validation/password-length']);
      return false;
    }

    if (formData.password !== formData.confirmPassword) {
      setError(errorMessages['validation/password-mismatch']);
      return false;
    }

    if (!formData.inviteCode.trim()) {
      setError(errorMessages['validation/invite-code-required']);
      return false;
    }

    return true;
  };


  const handleResendVerification = async () => {
    try {
      await authService.resendVerificationEmail(formData.email, registeredPassword);
      return true;
    } catch (error) {
      console.error('Erro ao reenviar:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('timeout')), 60000)
      );

      const attemptRegister = async (retries = 2) => {
        try {
          return await register(
            formData.email,
            formData.password,
            formData.name,
            formData.inviteCode
          );
        } catch (error) {
          if (retries > 0 && error.code === 'auth/network-request-failed') {
            await new Promise(resolve => setTimeout(resolve, 2000));
            return attemptRegister(retries - 1);
          }
          throw error;
        }
      };

      const result = await Promise.race([
        attemptRegister(),
        timeoutPromise
      ]);

      setRegisteredPassword(formData.password);
      setShowVerificationModal(true);
    } catch (error) {
      console.error('Erro detalhado:', {
        errorCode: error.code,
        errorMessage: error.message,
        stack: error.stack
      });
      setError(error.message);
    } finally {
      // Garante que o loading será desativado em qualquer situação
      setLoading(false);
    }
};

  const ErrorMessage = ({ message }) => (
    <div 
      className="text-sm text-center text-red-500 bg-red-100 border border-red-200 rounded-md p-2 animate-fadeIn"
      role="alert"
    >
      {message}
    </div>
  );

  return (
    <div className="w-full max-w-sm bg-gray-800 rounded-lg shadow-xl p-6">
      <h2 className="text-2xl font-bold mb-6 text-center text-white">Registro</h2>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1 text-white">Nome</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 text-white focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
            autoComplete="name"
            placeholder="Digite seu nome"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1 text-white">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 text-white focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
            autoComplete="email"
            placeholder="Digite seu email"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1 text-white">Senha</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 text-white focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
            minLength={6}
            autoComplete="new-password"
            placeholder="Digite sua senha"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1 text-white">
            Confirmar Senha
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 text-white focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
            minLength={6}
            autoComplete="new-password"
            placeholder="Confirme sua senha"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1 text-white">
            Código de Convite
          </label>
          <input
            type="text"
            name="inviteCode"
            value={formData.inviteCode}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-700 rounded border border-gray-600 text-white focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 uppercase"
            required
            placeholder="Digite o código de convite"
          />
        </div>

        

        <button
          type="submit"
          disabled={loading}
          className={`w-full py-2.5 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {loading ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Registrando...
            </>
          ) : (
            'Registrar'
          )}
        </button>
      </form>

      <p className="mt-4 text-center text-white">
        Já tem conta?{' '}
        <button
          type="button"
          onClick={onSwitchToLogin}
          className="text-blue-500 hover:text-blue-400 focus:outline-none focus:underline"
        >
          Faça login
        </button>
      </p>
      {error && <ErrorMessage message={error} />}

      {showVerificationModal && (
        <EmailVerificationModal
          email={formData.email}
          onClose={() => {
            setShowVerificationModal(false);
            onSwitchToLogin();
          }}
          onResendEmail={handleResendVerification}
        />
      )}
    </div>
  );
};

export default RegisterForm;